import { EDUCATOR_PROFILE } from '@constants/segment';
import SimpleLayout from '@layout/SimpleLayout/SimpleLayout';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const AuthorIndex = () => {
  const router = useRouter();
  const {
    query: { authorID }
  } = router;

  useEffect(() => {
    router.replace('/[authorID]/batches', `/${authorID}/batches`, {
      shallow: true
    });
  }, [router, authorID]);

  return null;
};

AuthorIndex.pageInfo = {
  name: EDUCATOR_PROFILE,
  isMWebSupported: true
};

export default AuthorIndex;

AuthorIndex.Layout = SimpleLayout;
