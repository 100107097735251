import PropTypes from 'prop-types';
import Header from './Header';
import Footer from '../Footer/BaseFooter';

const SimpleLayout = ({
  children,
  className,
  showFooter,
  showLogin,
  layoutOptions = {}
}) => {
  return (
    <>
      <Header
        showLogin={layoutOptions.showLogin ?? showLogin}
        className={className}
      />
      {children}
      <Footer display={layoutOptions.showFooter ?? showFooter} />
    </>
  );
};

SimpleLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  showFooter: PropTypes.bool,
  showLogin: PropTypes.bool
};

SimpleLayout.defaultProps = {
  className: '',
  showFooter: true,
  showLogin: false
};

export default SimpleLayout;
